import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { UsP } from "../components/sections"

const UsPage = () => (
  <Layout>
    <SEO title="Page Us" />
    <UsP />
  </Layout>
)

export default UsPage
